import * as Sentry from '@sentry/react'
import NextErrorComponent, { ErrorProps as NextErrorProps } from 'next/error'

export type ErrorPageProps = {
  err: Error
  statusCode: number
  isReadyToRender: boolean
  children?: React.ReactElement
}

export type ErrorProps = {
  isReadyToRender: boolean
} & NextErrorProps

const ErrorPage = (props: ErrorPageProps): JSX.Element => {
  const { statusCode, isReadyToRender, err, children = null } = props
  // eslint-disable-next-line no-debugger

  if (process.env.NEXT_PUBLIC_APP_STAGE !== 'development') {
    console.warn(
      'ErrorPage - Unexpected error caught, it was captured and sent to Sentry. Error details:'
    )
    console.error(err)
  }

  if (!isReadyToRender && err) {
    Sentry.captureException(err)
  }

  return <>{children ?? <NextErrorComponent statusCode={statusCode} />}</>
}

export default ErrorPage
